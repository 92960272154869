import React from 'react';

function PageHeader() {
  return (
    <header className="">
      <h1 className="text-teal-900 font-sans text-2xl font-semibold">Is there something to do?</h1>
    </header>
  );
}

export default PageHeader;
